import React, { Component } from "react";
import Container from "../container";
import Footer from "../footer";
import Nav from "../nav";

import arrow from "../../images/arrow.svg";

class ResourcesTemplate extends Component {
  render() {
    const { header, subheader, image, url } = this.props;
    return (
      <main className="grey">
        <Nav />
        <Container>
          <div className="resources__template">
            <div className="resources__template__header">
              <h1>{header}</h1>
              <p>{subheader}</p>
            </div>
            <div className="resources__template__content">
              <img src={image} alt={header} />
              <a href={url} target="_blank" rel="noreferrer">
                <button className="btn">
                Duplicate Template
                  <img src={arrow} alt="Arrow" />
                </button>
              </a>
            </div>
          </div>
        </Container>
        <Footer />
      </main>
    );
  }
}

export default ResourcesTemplate;
