import React, { Component } from 'react'
import ResourcesTemplate from '../../components/resources/template'

import "../../styles/resources.scss";

import designcontractImage from "../../images/resources/designcontract.png";


export class DesignContract extends Component {
  render() {
    return (
      <ResourcesTemplate
      header="Design Contract Template"
      subheader="This serves as a formal contract between a Designer and client to set expectations and condition for services provided."
      image={designcontractImage}
      url="#"   
      />
    )
  }
}

export default DesignContract